<template>
  <Type :type="type" :key="keyComp"></Type>
</template>

<script>
import Types from "../components/Type/Types";
import Type from "../components/Type";
import Reload from "../mixins/Reload";
export default {
  mixins: [Reload, Types],
  components: { Type },
  data() {
    return {
      type: { type: -1 },
      products: [],
    };
  },
  watch: {
    keyComp() {
      this.GET_TYPE_FROM_PARAMS();
    },
  },
  methods: {
    // paramsdan hangi type olduğu alınır.
    GET_TYPE_FROM_PARAMS() {
      const type = this.$route.params.type;
      console.log(type);
      const result = this.Types.find((t) => t.id === type);
      if (result) this.GET_LIST_OF_TYPE(result);
      else this.$router.push("/error");
    },
    GET_LIST_OF_TYPE(result) {
      this.type = result;
    },
  },
  created() {
    this.GET_TYPE_FROM_PARAMS(); // paramsdan TYPE getir.
  },
};
</script>
