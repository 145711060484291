<template>
  <div>
    <PageLoading v-if="getCategoriesAreLoading"></PageLoading>
    <Category v-else :key="keyComp"></Category>
  </div>
</template>

<script>
import Category from "../components/Category";
import Reload from "../mixins/Reload";
import PageLoading from "../components/Shared/PageLoading.vue";
export default {
  mixins: [Reload],
  components: { Category, PageLoading },
  computed: {
    getCategories() {
      return this.$store.state.Category.categories;
    },
    getCategoriesAreLoading() {
      return this.$store.state.Category.categoriesAreLoading;
    },
  },
};
</script>
