<template>
  <div>
    <!-- <Banner :title="type.title"></Banner> -->
    <!--channel-cover end-->
    <PageLoading v-if="productsAreLoading"></PageLoading>
    <Products
      v-else
      :search="search"
      :view-all-url="$route.path"
      @search:product="SET_SEARCH_PRODUCT"
      :limit="12"
      :productList="productList"
      :product-type="type.type"
      :newProductPath="type.newProductPath"
      has-info
      :title="type.title"
    >
    </Products>
    <div class="d-flex justify-content-center">
      <BoostrapPagination
        @change="SET_PAGINATION"
        :page="page"
        :pages="pages"
      ></BoostrapPagination>
    </div>
    <!--Featured Videos end-->
  </div>
</template>

<script>
import Products from "../List";
import PageLoading from "../Shared/PageLoading.vue";
import BoostrapPagination from "gurhan/vue/BootstrapPagination.vue";
export default {
  components: { Products, PageLoading, BoostrapPagination },
  props: {
    type: {
      default: () => {},
    },
  },
  data() {
    return {
      page: 1,
      limit: 10,
      search: "",
      categoryId: null,
    };
  },
  computed: {
    productsAreLoading() {
      return this.$store.state[this.type.storePath][
        this.type.stateListLoadingPath
      ];
    },
    productList() {
      return this.$store.state[this.type.storePath][this.type.statePath].filter(
        (p) => p.page === this.page
      );
    },
    pages() {
      return Math.ceil(
        this.$store.state[this.type.storePath].count / this.limit
      );
    },
  },
  watch: {
    $route() {
      this.GET_QUERY();
    },
  },
  methods: {
    SET_SEARCH_PRODUCT(search) {
      this.search = search;
      this.page = 1;
      this.GET_LIST();
    },
    SET_PAGINATION(page) {
      this.page = page;
      this.$router.replace({ query: { ...this.$route.query, page } });
      this.GET_LIST();
    },
    GET_QUERY() {
      this.page = 1;
      this.categoryId = null;
      if (!isNaN(Number(this.$route.query.page)))
        this.page = Number(this.$route.query.page);
      if (!isNaN(Number(this.$route.query.categoryId)))
        this.categoryId = Number(this.$route.query.categoryId);
      this.GET_LIST();
    },
    GET_LIST() {
      this.$store.dispatch(this.type.dispatchPath, {
        page: this.page,
        search: this.search,
        limit: 10,
        type: this.type,
        categoryId: this.categoryId,
      });
    },
  },
  mounted() {
    this.GET_QUERY();
  },
};
</script>
