<template>
  <div>
    <Banner
      editable
      :title="category.name"
      @change:image="SET_IMAGE_WHICH_IS_CHANGED"
      :image="image"
      :src="$api.image(category.imagePath)"
    ></Banner>
    <!--channel-cover end-->
    <EditCategory :item="category"></EditCategory>
    <Info
      class="mb-5"
      :title="category.name"
      :viewAllUrl="$route.fullPath"
      :avatar="$api.image(category.imagePath)"
      :editUrl="{ modal: 'opened' }"
      no-search
    ></Info>
    <div class="bg-light card">
      <div class="container text-center py-4">
        <h2>{{ $t("products") }}</h2>
      </div>
    </div>
    <Products
      class="stripped-light"
      v-for="(type, i) in Types"
      :key="i"
      :view-all-url="type.domain + `?categoryId=${category.id}`"
      :newProductPath="type.newProductPath + `&category=${category.id}`"
      @search:product="(s) => SET_SEARCH_PRODUCT(s, type)"
      :limit="4"
      :product-type="type.type"
      :productList="SET_RETURN_PRODUCT_LIST(type.computed)"
      has-info
      :title="type.title"
      :categoryId="categoryId"
    >
    </Products>
    <!--Featured Videos end-->
  </div>
</template>

<script>
import Types from "../Type/Types.js";
import Banner from "../List/Banner.vue";
import Products from "../List";
import Info from "../List/Info.vue";
import EditCategory from "./EditCategory.vue";
export default {
  components: { Banner, Products, Info, EditCategory },
  mixins: [Types],
  data() {
    return {
      categoryId: -1,
      category: { id: -1 },
      image: {
        uri: "",
        file: [],
      },
    };
  },
  computed: {
    getCategories() {
      return this.$store.state.Category.categories;
    },
    getCourses() {
      return this.$store.state.Courses.courses.filter((c, i) => i < 12);
    },
    getBooks() {
      return this.$store.state.Books.books.filter((c, i) => i < 12);
    },
    getEvents() {
      return this.$store.state.Events.events.filter((c, i) => i < 12);
    },
    getSeances() {
      return this.$store.state.Seances.seances.filter((c, i) => i < 12);
    },
  },
  methods: {
    SET_SEARCH_PRODUCT(search, req) {
      this.search = search;
      this.$store.dispatch(req.dispatchPath, {
        limit: 4,
        type: req,
        search,
        page: 1,
      });
    },
    SET_RETURN_PRODUCT_LIST(key) {
      return this[key];
    },
    async SET_IMAGE_WHICH_IS_CHANGED(e) {
      this.image = e;
      const fd = new FormData();
      fd.append("Image", e.file);
      fd.append("Name", this.category.name);
      fd.append("Description", this.category.description);
      this.$store.commit("SET_LOADING_MODAL", {
        loading: true,
        alertBox: { status: false },
      });
      const response = await this.$store.dispatch("SEND_TO_UPDATE_CATEGORY", {
        category: {
          id: this.category.id,
          name: this.category.name,
          description: this.category.description,
          imagePath: e.uri,
        },
        fd,
      });
      this.$store.commit("SET_LOADING_MODAL", {
        loading: true,
        alertBox: response,
      });
    },
    // paramsdan hangi kategori olduğu alınır.
    GET_CATEGORY_ID_FROM_PARAMS() {
      this.categoryId = this.$route.params.id;
      const result = this.getCategories.find(
        (c) => c.id === Number(this.categoryId)
      );
      if (result) this.category = result;
      else this.$router.push("/error");
    },
  },
  created() {
    this.GET_CATEGORY_ID_FROM_PARAMS(); // paramsdan category id getir.
    this.$store.dispatch("GET_COURSES", {
      limit: 12,
      type: this.FIND_TYPE(2),
      categoryId: this.categoryId,
    });
    this.$store.dispatch("GET_BOOKS", {
      limit: 12,
      type: this.FIND_TYPE(4),
      categoryId: this.categoryId,
    });
    this.$store.dispatch("GET_EVENTS", {
      limit: 12,
      type: this.FIND_TYPE(1),
      categoryId: this.categoryId,
    });
    this.$store.dispatch("GET_SEANCES", {
      limit: 12,
      type: this.FIND_TYPE(3),
      categoryId: this.categoryId,
    });
  },
};
</script>
